/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import { SkillsetMatching, SkillsetSkills } from 'types/SkillsetMatching';
import { Row, Col, Badge } from 'antd';
import React from 'react';
import SkillsetMatchingActionsComponent from 'components/Actions/SkillsetMatchingActions';
import i18next from '../i18n';
import * as Styled from '../ui-v2/components/Skills/SkillsStyles';

const employee: string = i18next.t('employee');
const candidate: string = i18next.t('candidate');
const archivedCandidate: string = i18next.t('archivedCandidate');

export const SkillsetColumns = [
  {
    title: i18next.t('type'),
    dataIndex: 'type',
    key: 'type',
    render: (record: string) =>
      record === 'employee' ? (
        <p
          style={{
            backgroundColor: '#CBC3E3',
            borderRadius: '25px',
            textAlign: 'center',
            margin: '0.5rem',
            padding: '0.3rem',
          }}
        >
          {employee}
        </p>
      ) : record === 'candidate' ? (
        <p
          style={{
            backgroundColor: '#90EE90',
            borderRadius: '25px',
            textAlign: 'center',
            margin: '0.5rem',
            padding: '0.3rem',
          }}
        >
          {candidate}
        </p>
      ) : (
        <p
          style={{
            backgroundColor: '#FAD5A5',
            borderRadius: '25px',
            textAlign: 'center',
            margin: '0.5rem',
            padding: '0.3rem',
          }}
        >
          {archivedCandidate}
        </p>
      ),
    width: 40,
    default: false,
    ellipsis: true,
    order: 0,
  },
  {
    title: i18next.t('firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    width: 30,
    default: true,
    order: 1,
  },
  {
    title: i18next.t('lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    width: 30,
    ellipsis: true,
    default: true,
    order: 2,
  },
  {
    title: i18next.t('skills'),
    dataIndex: 'skills',
    key: 'skills',
    width: 150,
    default: false,
    order: 3,
    ellipsis: true,
    render: (record: SkillsetSkills[]) => (
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        {record?.map((skill: SkillsetSkills) => (
          <Badge
            count={skill?.yearsOfExperience}
            style={Styled.SkillBadgeStyles}
            key={skill?.id}
          >
            <Styled.Tag key={skill?.id}>
              <Row justify="center" align="middle" className="skill-tag">
                <Col>
                  <Styled.TagLabel>{skill?.subCategoryName}</Styled.TagLabel>
                </Col>
              </Row>
            </Styled.Tag>
          </Badge>
        ))}
      </div>
    ),
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 40,
    render: (record: string, row: SkillsetMatching) => (
      <SkillsetMatchingActionsComponent row={row} />
    ),
    default: false,
    order: 4,
    fixed: 'right',
  },
];
