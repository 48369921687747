import { Col, Row } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Id } from 'types/Id';
import { EdititingIcon } from 'Icons/EditIcon';
import AddFeedbackModal from 'ui-v2/components/Employees/EmployeeGoals/AddFeedbackModal';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const GoalActions: React.FC<Id> = ({ id }) => {
  const [modal, setModal] = useState(false);

  const onEditClick = () => {
    setModal(true);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <IconButton onClick={onEditClick}>
            <EdititingIcon />
          </IconButton>
        </Row>
      </Col>
      {modal && (
        <AddFeedbackModal
          open={modal}
          closeModal={() => setModal(false)}
          goalId={id}
        />
      )}
    </Row>
  );
};

export default GoalActions;
