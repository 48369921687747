import React from 'react';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { useDispatch } from 'react-redux';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import useGoalData from 'ui-v2/hooks/useGoalData';

export default function GoalList() {
  const { columns, take } = useHeader();
  const dispatch = useDispatch();
  const { goals, pagination, loading, fetchGoals } = useGoalData();

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
    };
    dispatch(fetchGoals(params));
  };

  return (
    <Content position="stretch">
      <DataGrid
        loading={loading}
        columns={columns}
        data={goals}
        paginationName="Goals"
        rowSelectionType="checkbox"
        totalItems={pagination?.itemCount}
        currentPage={pagination?.page}
        totalPages={pagination?.pageCount}
        onPageChange={onPageChange}
        showSearch
      />
    </Content>
  );
}
