/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import {
  HeartOutlined,
  ShareAltOutlined,
  CommentOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import {
  ActionButton,
  ActionButtons,
  CommentAvatar,
  Comment,
  CommentContent,
  CommentList,
  CommentText,
  CommentUser,
  CommentsSection,
  PostContainer,
  CommentInputWrapper,
  CommentInput,
  AddCommentButton,
  PostContent,
  NoCommentsMessage,
  PinIconWrapper,
} from '../FeedStyled';
import SharePost from '../SharePost/SharePost';

interface PostProps {
  post: any;
  onLike: (id: string) => void;
  onComment: (id: string, comment: string) => void;
  onPin: (id: string, currentPinned: boolean) => void;
}

const FeedPost: React.FC<PostProps> = ({ post, onLike, onComment, onPin }) => {
  const [comment, setComment] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleAddComment = () => {
    if (comment.trim()) {
      onComment(post.id, comment);
      setComment('');
    }
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const openShareModal = () => {
    setShowModal(true);
  };

  const closeShareModal = () => {
    setShowModal(false);
  };

  return (
    <PostContainer>
      <PostContent>
        <PinIconWrapper
          onClick={() => onPin(post.id, post.pinned)}
          title={post.pinned ? 'Unpin Post' : 'Pin Post'}
        >
          <PushpinOutlined style={{ color: post.pinned ? 'gold' : 'gray' }} />
        </PinIconWrapper>

        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </PostContent>
      <ActionButtons>
        <ActionButton onClick={() => onLike(post.id)}>
          <HeartOutlined
            style={{ color: post.likes.length !== 0 ? 'red' : 'gray' }}
          />
          {post.postLikes}
        </ActionButton>

        <ActionButton onClick={openShareModal}>
          <ShareAltOutlined />
        </ActionButton>

        <ActionButton onClick={toggleComments}>
          <CommentOutlined />
        </ActionButton>
      </ActionButtons>

      {showComments && (
        <CommentsSection>
          {post.comments.length === 0 ? (
            <NoCommentsMessage>No Comments Yet</NoCommentsMessage>
          ) : (
            <CommentList>
              {post.comments.map((comment: any) => (
                <Comment key={comment.id}>
                  <CommentAvatar>
                    {comment.employee.firstName[0].toUpperCase()}
                    {comment.employee.lastName[0].toUpperCase()}
                  </CommentAvatar>
                  <CommentContent>
                    <CommentUser>
                      {comment.employee.firstName}
                      {comment.employee.lastName}
                    </CommentUser>
                    <CommentText>{comment.content}</CommentText>
                  </CommentContent>
                </Comment>
              ))}
            </CommentList>
          )}
        </CommentsSection>
      )}

      <CommentInputWrapper>
        <CommentInput
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Add a comment..."
        />
        <AddCommentButton onClick={handleAddComment}>Post</AddCommentButton>
      </CommentInputWrapper>

      <SharePost
        isOpen={showModal}
        onClose={closeShareModal}
        postId={post.id}
      />
    </PostContainer>
  );
};

export default FeedPost;
