import { Dispatch } from 'redux';
import { getPosts } from 'api/feedService';
import { FeedAction } from './types';

export const fetchFeed = () => (dispatch: Dispatch) => {
  dispatch({
    type: FeedAction.FETCH_FEED_START,
  });
  getPosts()
    .then((res) => {
      dispatch({
        type: FeedAction.FETCH_FEED_SUCCESS,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: FeedAction.FETCH_FEED_ERRORED,
        payload: e,
      });
    });
};

export const clearFetchFeed = () => (dispatch: Dispatch) => {
  dispatch({
    type: FeedAction.CLEAR_FEED,
  });
};
