import agent from 'api/agent';
import { QueryParamsType } from 'types/QueryParams';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import {
  GoalCreateDTO,
  GoalFeedBackCreateDTO,
  GoalUpdateDTO,
} from 'types/Goal';
import { apiURLs } from './constants';

export const getAllGoals = (queryParams: QueryParamsType) =>
  agent.post(`${apiURLs.goal}/get`, searchFields(queryParams, apiURLs.goal));

export const createNewGoal = (values: GoalCreateDTO) =>
  agent.post(apiURLs.goal, values);

export const updateGoal = (value: GoalUpdateDTO, id: string) =>
  agent.put(`${apiURLs.goal}/${id}`, value);

export const getGoalById = (goalId: string) =>
  agent.get(`${apiURLs.goal}/${goalId}`);

export const getGoalsByEmployeeId = (employeeId: string) =>
  agent.get(`${apiURLs.goal}/employee/${employeeId}`);

export const deleteGoal = (id: string) => agent.delete(`${apiURLs.goal}/${id}`);

export const createGoalFeedback = (values: GoalFeedBackCreateDTO) =>
  agent.post(apiURLs.goalFeedback, values);

export const getAllFeedbackForGoal = (goalId: string) =>
  agent.get(`${apiURLs.goalFeedback}/goal/${goalId}`);
