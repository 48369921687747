/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Col, Row, Spin, Typography, Select } from 'antd';
import { getAttendanceMatrix } from 'api/employeeAttendanceService';
import { useTranslation } from 'react-i18next';
import CardItem from 'ui-v2/components/Card';
import { toastErrorMessages } from 'utils/utilFunctions';
import {
  StyledContainer,
  StyledXAxisLabel,
  StyledNoDataText,
  StyledSelectWrapper,
  StyledYAxisLabel,
  StyledGridCell,
  StyledXAxisWrapper,
  StyledScrollableWrapper,
  StyledYAxisWrapper,
  StyledScrollableContent,
} from './AttendanceMatrixStyles';

const { Option } = Select;

interface AttendanceMatrixData {
  xLabels: string[];
  yLabels: string[];
  data: number[][];
}

const AttendanceMatrixComponent: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [matrixData, setMatrixData] = useState<AttendanceMatrixData>({
    xLabels: [],
    yLabels: [],
    data: [],
  });
  const [selectedMonth, setSelectedMonth] = useState<string>('2024-09');

  const months = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(2024, i, 1);
    return {
      value: `${date.getFullYear()}-${(i + 1).toString().padStart(2, '0')}`,
      label: date.toLocaleString('default', { month: 'long' }),
    };
  });

  async function fetchMatrixData(month: string) {
    setLoading(true);
    try {
      const { data } = await getAttendanceMatrix(month);
      setMatrixData(data);
    } catch (error) {
      toastErrorMessages(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchMatrixData(selectedMonth);
  }, [selectedMonth]);

  const renderContent = () => {
    if (loading) {
      return <Spin />;
    }

    if (
      matrixData.data.length === 0 ||
      !Array.isArray(matrixData.xLabels) ||
      !Array.isArray(matrixData.yLabels) ||
      !Array.isArray(matrixData.data)
    ) {
      return (
        <StyledNoDataText justify="center" align="middle">
          <Col>
            <Typography.Text style={{ display: 'block', textAlign: 'center' }}>
              {t('noDataYet.')}
            </Typography.Text>
            <Typography.Link
              style={{ display: 'block', textAlign: 'center' }}
              href="employees/add-employee"
            >
              {t('addSomeEmployeesFirst!')}
            </Typography.Link>
          </Col>
        </StyledNoDataText>
      );
    }

    const visibleEmployees = matrixData.yLabels.slice(0, 15);
    const visibleData = matrixData.data.slice(0, 15);

    return (
      <StyledContainer>
        {/* This container handles both horizontal and vertical scroll */}
        <StyledScrollableWrapper>
          {/* Sticky Y-Axis Labels */}
          <StyledYAxisWrapper>
            {visibleEmployees.map((label, index) => (
              <StyledYAxisLabel key={index}>{label}</StyledYAxisLabel>
            ))}
          </StyledYAxisWrapper>

          {/* Main Content (X-Axis Labels + Heatmap Grid) */}
          <div style={{ display: 'inline-block' }}>
            {/* X-Axis Labels - These will be sticky at the top */}
            <StyledXAxisWrapper>
              {matrixData.xLabels.map((label, index) => (
                <StyledXAxisLabel key={index}>{label}</StyledXAxisLabel>
              ))}
            </StyledXAxisWrapper>

            {/* Scrollable Heatmap Data */}
            <StyledScrollableContent
              xLength={matrixData.xLabels.length}
              yLength={visibleEmployees.length}
            >
              {visibleData.map((row, rowIndex) =>
                row.map((cell, colIndex) => (
                  <StyledGridCell key={`${rowIndex}-${colIndex}`} ratio={cell}>
                    {cell === 1 ? '✔' : ''}
                  </StyledGridCell>
                ))
              )}
            </StyledScrollableContent>
          </div>
        </StyledScrollableWrapper>
      </StyledContainer>
    );
  };

  return (
    <CardItem title={t('Attendance Matrix')} minHeight={450}>
      <>
        <Row justify="center">
          <StyledSelectWrapper span={8}>
            <Select
              value={selectedMonth}
              onChange={setSelectedMonth}
              style={{ width: '100%' }}
            >
              {months.map((month) => (
                <Option key={month.value} value={month.value}>
                  {month.label}
                </Option>
              ))}
            </Select>
          </StyledSelectWrapper>
        </Row>
        {renderContent()}
      </>
    </CardItem>
  );
};

export default AttendanceMatrixComponent;
