import { Col, Row } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  position: relative;
`;

export const StyledXAxisWrapper = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
`;

export const StyledXAxisLabel = styled.div`
  color: #777;
  font-size: 0.8rem;
  text-align: center;
  transform: rotate(-67deg);
  transform-origin: top left;
  width: 2rem;
  height: 2rem;
  background-color: white;
`;

export const StyledYAxisWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
`;

export const StyledYAxisLabel = styled.div`
  color: #777;
  padding-left: 0.5rem;
  font-size: 14px;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0.5rem;
`;

export const StyledScrollableWrapper = styled.div`
  display: flex;
  overflow: auto;
  height: 500px; /* Adjust based on your needs */
`;

export const StyledScrollableContent = styled.div<{
  xLength: number;
  yLength: number;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.xLength}, 2rem);
  grid-template-rows: repeat(${(props) => props.yLength}, 2rem);
  position: relative;
  overflow: scroll;
`;

export const StyledGridCell = styled.div<{ ratio: number }>`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ ratio }) => `rgba(12, 160, 44, ${ratio})`};
`;

export const StyledNoDataText = styled(Row)`
  min-height: 350px;
`;

export const StyledSelectWrapper = styled(Col)`
  margin-bottom: 1rem;
  width: 100%;
`;
