import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { fetchSkillsetMatchingByOpenPositionId } from 'redux/skillsetMatching/action';
import { useSearchParams } from 'react-router-dom';
import i18next from '../../../../i18n';

export default function SkillsetMatchingFilter() {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id: string = searchParams.get('id') as string;

  const onChecked = (checked: boolean) => {
    dispatch(
      fetchSkillsetMatchingByOpenPositionId(
        id,
        {
          pageOptions: {
            page: 1,
            take: 10,
          },
        },
        checked
      )
    );
  };

  const SkillsetMatchingFilterConfig: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'yearsOfExperience',
          label: i18next.t('yearsOfExperience'),
          type: InputTypes.SWITCH,
          direction: 'vertical',
          switchProps: {
            checkedChildren: true,
            unCheckedChildren: false,
          },
          onChecked,
        },
      ],
    ],
    [form]
  );
  return (
    <GenericForm formConfiguration={SkillsetMatchingFilterConfig} form={form} />
  );
}
