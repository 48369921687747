import agent from './agent';
import { apiURLs } from './constants';

export const getCurrentAttendance = async (employeeId: string) =>
  agent.get(`${apiURLs.employeeAttendance}/current/${employeeId}`);

export const getAttendanceMatrix = async (month: string) => {
  try {
    const response = await agent.get(
      `${apiURLs.employeeAttendance}/attendance-matrix`,
      {
        params: {
          month,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(`Error fetching attendance matrix: ${error}`);
  }
};

export const checkIn = async (employeeId: string) =>
  agent.post(`${apiURLs.employeeAttendance}/check-in/${employeeId}`);

export const checkOut = async (employeeId: string) =>
  agent.post(`${apiURLs.employeeAttendance}/check-out/${employeeId}`);
