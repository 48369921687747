import agent from 'api/agent';
import { FeedQueryParams } from 'types/QueryParams';
import { CreateComment, PinPost, Post } from 'types/Post';
import { apiURLs } from './constants';

export const createPost = (post?: Post) => agent.post(`${apiURLs.feed}`, post);

export const likePost = (postId?: string) => agent.post(`/like-post/${postId}`);

export const commentPost = (payload: CreateComment) =>
  agent.post('/comment-post', payload);

export const sharePost = (id: string, employeeId: string) =>
  agent.post(`${apiURLs.feed}/${id}/share`, employeeId);

export const pinPost = (postId: string, pinned: PinPost) =>
  agent.put(`/feed/${postId}/pin`, pinned);

export const getPosts = (queryParams?: FeedQueryParams) =>
  agent.post(`${apiURLs.feed}/get`, queryParams);
