import { getAllGoals } from 'api/goalService';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MetaPagination } from 'types/GridTable';
import { QueryParamsType } from 'types/QueryParams';

export default function useGoalData() {
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<MetaPagination>();

  const fetchGoals = async (params: QueryParamsType) => {
    setLoading(true);

    try {
      const response = await getAllGoals(params);
      if (response?.data?.data) {
        setGoals(response.data.data);
      }
      if (response?.data?.meta) {
        setPagination(response.data.meta);
      }
    } catch {
      toast.warning('Failed to get goals!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGoals({
      page: 1,
      pageCount: 20,
    });
  }, []);

  return {
    goals,
    loading,
    pagination,
    fetchGoals,
  };
}
