import { Goal } from 'types/Goal';
import GoalActions from 'components/Actions/GoalActions';
import React from 'react';
import i18next from '../i18n';

export const GoalColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    default: true,
    order: 0,
  },
  {
    title: i18next.t('Completed'),
    dataIndex: 'completed',
    key: 'completed',
    default: true,
    order: 1,
    render: (_: string, row: Goal) => <p>{`${row.completed}%`}</p>,
  },
  {
    title: i18next.t('Certificate'),
    dataIndex: 'certificate',
    key: 'certificate',
    default: false,
    order: 3,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: Goal) => <GoalActions id={row?.id} />,
    default: false,
    order: 6,
  },
];
