import React, { useState } from 'react';
import { t } from 'i18next';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { Col, Row } from 'antd';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { createPost } from 'api/feedService';
import { Post } from 'types/Post';
import { toastErrorMessages } from 'utils/utilFunctions';
import GenericModal from '../../GenericModal';

interface AddPostProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const AddPost: React.FC<AddPostProps> = ({ onClose, onSubmit, open }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
  };

  const handleSubmit = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const content = draftToHtml(rawContentState);

    if (content.trim()) {
      setLoading(true);
      try {
        await createPost({ content } as Post);
        onSubmit();
        onClose();
      } catch (error) {
        toastErrorMessages(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <GenericModal
      title={t('Add Post')}
      open={open}
      closeModal={onClose}
      icon={<WorkExperienceIcon />}
    >
      <FormItemStyled>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          placeholder="Write something..."
        />
      </FormItemStyled>
      <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
        <Row>
          <Col span={11}>
            <StyledButton onClick={onClose} htmlType="reset" danger>
              {t('cancel')}
            </StyledButton>
          </Col>
          <Col span={11} offset={2}>
            <StyledButton
              loading={loading}
              type="primary"
              onClick={handleSubmit}
              htmlType="submit"
            >
              {t('confirm')}
            </StyledButton>
          </Col>
        </Row>
      </FormItemStyled>
    </GenericModal>
  );
};

export default AddPost;
